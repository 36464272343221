import { Button } from "react-bootstrap"
import { DescriptionProps } from "../../utils/Interfaces"
import Link from "./Link"
import { linkStarter, linkTextStarter } from "../../utils/Constants"

export default (props: DescriptionProps) => {
    return (
        <div style={{
            backgroundColor: "#e2e2e2",
            paddingTop: "1em",
            marginTop: "10em",
            paddingBottom: "1em"
        }}>
            <div className="main-screen-root-div">
                <div style={{ marginTop: "15%" }} className="desc-root">
                    <h2 className="desc-h2">About</h2>
                    <hr style={{
                        width: "50%", 
                        position: "relative",
                        left: "25%"
                    }}/>
                    {
                        props.aboutDescription?.map(eachDesc => 
                            <p className="page-desc">
                            {
                                eachDesc.startsWith(linkStarter) ?
                                    <Link href={eachDesc.split(linkStarter)[1].split(" ")[0]} 
                                        text={eachDesc.split(linkTextStarter)[1].split(" ")[0]} /> :
                                    eachDesc
                            }
                            </p>
                        )
                    }
                </div>
                <div className="desc-root">
                    <h2 className="desc-h2">How this tool is usefull to you ?</h2>
                    <hr style={{
                        width: "50%", 
                        position: "relative",
                        left: "25%"
                    }}/>
                    <div className="page-desc" style={{
                        textAlign: "left"
                    }}>
                        {
                            Object.keys(props.advantages).map(eachAdv => {
                                return (
                                <div style={{marginBottom: "50px"}}>
                                    <h5>{eachAdv}</h5>
                                    <p>{props.advantages[eachAdv]}</p>
                                </div>)
                            })
                        }
                    </div>
                </div>
                <div style={{ marginTop: "15%" }} className="desc-root">
                    <h2 className="desc-h2">Learn More About JSON files</h2>
                    <hr style={{
                        width: "50%", 
                        position: "relative",
                        left: "25%"
                    }}/>
                    <div className="page-desc">
                        <div>{props.moreAboutJson.description}</div>
                        <div>
                        {
                            Object.keys(props.moreAboutJson.links).map(eachLinkTitle => 
                                <Link 
                                    href={props.moreAboutJson.links[eachLinkTitle]} 
                                    text={eachLinkTitle} 
                                    className="desc-ln-mr-lnk" target="_blank"/>
                            )
                        }
                        </div>
                    </div>
                </div>
                <div className="desc-root">
                    <h2 className="desc-h2">JSON Standards</h2>
                    <hr style={{
                        width: "50%", 
                        position: "relative",
                        left: "25%"
                    }}/>
                    <div className="page-desc">
                        <div>{props.standards.description}</div>
                        <div>
                        {
                            Object.keys(props.standards.links).map(eachLinkTitle => 
                                <Link 
                                    href={props.standards.links[eachLinkTitle]} 
                                    text={eachLinkTitle} 
                                    className="desc-ln-mr-lnk" target="_blank"/>
                            )
                        }
                        </div>
                    </div>
                </div>
                <div className="desc-root">
                    <h2 className="desc-h2">Frequently Asked Questions(FAQs)</h2>
                    <hr style={{
                        width: "50%", 
                        position: "relative",
                        left: "25%"
                    }}/>
                    <div style={{
                        textAlign: "left"
                    }} className="page-desc">
                    {
                        props.faqs.map(eachFaq => {
                            return (
                                <div style={{marginBottom: "50px"}}>
                                    <h5>{eachFaq.question}</h5>
                                    <p>{eachFaq.answer}</p>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
                <div className="desc-root">
                    <h2 className="desc-h2">Contact Us</h2>
                    <div className="page-desc">
                        <Button href="https://simplevalidator.com/ContactUs">Contact Us</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}