import React, { Component, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import Header from '../components/common/Header';

var defaultText = "Its too early. we are on the way...";
var count = 0;

export default () => {
	const [text, setText] = useState("")

	useEffect(() => {
		setTimeout(() => {
			if(count < defaultText.length) {
				setText(text + defaultText[count])
				count += 1
			}
			else if(count === defaultText.length) {
				setTimeout(() => {
					setText(text + "_")
					count += 1
				}, 250);
			}
			else if(count === defaultText.length + 1) {
				setTimeout(() => {
					setText(text.substring(0, text.length - 1))
					count -= 1
				}, 300);
			}
		}, 20)
	})
  
	return (
		<div style={{
			position:"relative",
			top: "50%",
			left: "10%"
		}}>
			<Header title='SimpleValidator - About' meta='SimpleValidator - About' />
			<h2 style={{
				fontFamily: "monospace"
			}}>{text}</h2>
			<Button href='/'>HOME</Button>
		</div>
	)
}