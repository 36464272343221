import Code from "../code_views/Code";
import DifferenceSummary from "../../components/common/DifferenceSummary";
import Toast, { error } from "../../components/common/Toast"
import { useState } from "react"
import { Button } from "react-bootstrap"
import { ComponentProps, JSONCompareResultObj, ValidateJsonObj } from "../../utils/Interfaces";
import { jsonCompare, validateJson } from "../../utils/core/Validator";
import FullScreenView from "../common/FullScreenView";

function handleJsonCompare(
        jsonText1: string, 
        jsonText2: string, 
        setJsonValidate: React.Dispatch<React.SetStateAction<ValidateJsonObj>>, 
        setToDisplayMsg: (toDisplay: boolean) => void, 
        setIsLoaded: (isLoaded: boolean) => void, 
        setJsonDiff: (jsonDiff: JSONCompareResultObj) => void,
        setReadyForScroll: (readyForScroll: boolean) => void
    ) {
    var validateJsonObj1: ValidateJsonObj = validateJson(jsonText1, setJsonValidate, setToDisplayMsg, setIsLoaded);
    var validateJsonObj2: ValidateJsonObj = validateJson(jsonText2, setJsonValidate, setToDisplayMsg, setIsLoaded);
    
    if(validateJsonObj1.error && validateJsonObj2.error) {
        setToDisplayMsg(true);
        error("Both JSON text have errors. Validate first.", setToDisplayMsg)
    }
    else if(validateJsonObj1.error) {
        setToDisplayMsg(true);
        error("Validation failed for left hand side JSON text. Error: " + validateJsonObj1.error, setToDisplayMsg);
    }
    else if(validateJsonObj2.error) {
        setToDisplayMsg(true);
        error("Validation failed for right hand side JSON text. Error: " + validateJsonObj2.error, setToDisplayMsg);
    }
    else {
        setJsonDiff(jsonCompare(jsonText1, jsonText2));
        setReadyForScroll(true)
    }
}

export default (props: ComponentProps) => {
    const [jsonText1, setJsonText1] = useState("");
    const [jsonText2, setJsonText2] = useState("");
    const [toDisplayMsg, setToDisplayMsg] = useState(false);
    const [isLoaded, setIsLoaded] = useState(true);
    const [readyForScroll, setReadyForScroll] = useState(false);
    const [jsonDiff, setJsonDiff] = useState<JSONCompareResultObj>({
        isValid: false,
        notIn1: [],
        notIn2: [],
        lines1: [],
        lines2: [],
        jsonText1: "",
        jsonText2: ""
    })
    const [jsonValidate, setJsonValidate] = useState<ValidateJsonObj>({
        resultString: "",
        result: false,
        error: ""
    });
    const [fullScreenEnabled1, setFullScrennEnabled1] = useState<boolean>(false);
    const [fullScreenEnabled2, setFullScrennEnabled2] = useState<boolean>(false);

    return (
        <div className="main-screen-root-div">
            <Toast />
            <h2 className="main-content-header-h2">{props.pages.json.compare.title}</h2>
            <p className="desc-p">{props.pages.json.compare.meta}</p>
            <div className="json-compare-div">
                <textarea style={{
                    position: "unset"
                }}
                    className="compare-textarea" 
                    placeholder="Enter your JSON text here"
                    onFocus={() => setReadyForScroll(false)}
                    onChange={ (e) => setJsonText1(e.target.value) }
                ></textarea>
                <Button style={{
                    height: "max-content",
                    position: "relative",
                    marginTop: "25%",
                    marginLeft: "1em",
                    marginRight: "1em"
                }} onClick={(e) => {
                    handleJsonCompare(
                        jsonText1, 
                        jsonText2, 
                        setJsonValidate, 
                        setToDisplayMsg, 
                        setIsLoaded, 
                        setJsonDiff,
                        setReadyForScroll
                    );
                } }>Compare</Button>
                <textarea style={{
                    position: "unset"
                }}
                    className="compare-textarea" 
                    placeholder="Enter your JSON text here"
                    onFocus={() => setReadyForScroll(false)}
                    onChange={ (e) => setJsonText2(e.target.value) }
                ></textarea>
            </div>
            { (jsonValidate.result) ? 
                <DifferenceSummary difference={jsonDiff} /> 
                : <></> 
            }
            {
                (jsonValidate.result) ? 
                <div className="json-compare-div" style={{
                    left: "0%"
                }}>
                    <FullScreenView child={
                        <Code 
                            fullScreenEnabled={fullScreenEnabled1} 
                            setFullScrennEnabled={setFullScrennEnabled1} 
                            linesToHighlight={jsonDiff.lines1} 
                            headerLeftMargin="1em" 
                            showViewAsButton={false} 
                            width="31em" 
                            title={"JSON 1"} 
                            content={jsonDiff.jsonText1} 
                            toShow={toDisplayMsg} 
                            readyForScroll={readyForScroll} 
                            demandedViewType="Read Only"
                        />
                    } enabled={fullScreenEnabled1} setFullScrennEnabled={setFullScrennEnabled1} />
                    <FullScreenView child={
                        <Code 
                            fullScreenEnabled={fullScreenEnabled2} 
                            setFullScrennEnabled={setFullScrennEnabled2} 
                            linesToHighlight={jsonDiff.lines2} 
                            headerLeftMargin="1em" 
                            showViewAsButton={false} 
                            width="31em" 
                            title={"JSON 2"} 
                            content={jsonDiff.jsonText2} 
                            toShow={toDisplayMsg} 
                            readyForScroll={false} 
                            demandedViewType="Read Only"
                        />
                    } enabled={fullScreenEnabled2} setFullScrennEnabled={setFullScrennEnabled2} />
                </div> : <></>
            }
            
        </div>
    )
}