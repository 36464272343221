import { Helmet } from "react-helmet"
import { HeadProps } from "../../utils/Interfaces"

export default (props: HeadProps) => {
    return (
        <Helmet>
			<title>{props.title}</title>
            <meta name="description" content={props.meta} />
	  	</Helmet>
    )
}