import Code from "../../components/code_views/Code";
import Loader from "../../components/common/Loader";
import Toast, { displayMessageCallback } from "../../components/common/Toast";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { ComponentProps, ValidateJsonObj } from "../../utils/Interfaces";
import { formatJson } from "../../utils/core/Validator";
import FullScreenView from "../common/FullScreenView";
import Header from "../Header";

export default (props: ComponentProps) => {
    const [textContent, setTextContent] = useState("");
    const [formattedJson, setFormattedJson] = useState<ValidateJsonObj>({
        resultString: "",
        result: false,
        error: ""
    });
    const [toDisplayMsg, setToDisplayMsg] = useState(false);
    const [isLoaded, setIsLoaded] = useState(true);
    const [readyForScroll, setReadyForScroll] = useState(false);
    const [fullScreenEnabled, setFullScrennEnabled] = useState<boolean>(false);

    useEffect(() => {
        displayMessageCallback(toDisplayMsg, formattedJson, setToDisplayMsg, false)
    })

    return (
        <div className="main-screen-root-div" style={{
            textAlign: "left"
        }}>
            <Toast />
            <h2 className="main-content-header-h2">{props.pages.json.formatter.title}</h2>
            <p className="desc-p">{props.pages.json.formatter.meta}
            </p>
            <textarea 
                id="content" 
                onChange={(e) => {setTextContent(e.target.value)}} 
                placeholder="Enter your JSON text here"
                onFocus={() => {
                    setReadyForScroll(false);
                }}
            ></textarea>
            <div style={{
                width: "fit-content",
                textAlign: "left",
                marginTop: "2%",
                position: "relative",
                left: "5%"
            }}>
                <Button style={{
                    width: "8em",
                    height: "2.5em"
                }}
                    className="process-btn" 
                    onClick={() => {
                        setIsLoaded(false);
                        setToDisplayMsg(true);
                        formatJson(textContent, setFormattedJson, setToDisplayMsg, setIsLoaded)
                        setReadyForScroll(true)
                    }}
                >
                    { isLoaded ? "Process" : <Loader /> }
                </Button>
            </div>
            {
                    formattedJson.result ? 
                    <FullScreenView child={
                        <Code 
                            fullScreenEnabled={fullScreenEnabled} 
                            setFullScrennEnabled={setFullScrennEnabled} 
                            showViewAsButton={true} 
                            readyForScroll={readyForScroll} 
                            title="Formatted JSON" 
                            content={formattedJson.resultString ? formattedJson.resultString : ""} 
                            toShow={toDisplayMsg} 
                        />
                    } enabled={fullScreenEnabled} setFullScrennEnabled={setFullScrennEnabled} />
                    :   <></>
            }
        </div>
    )
}