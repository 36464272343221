import React, { useEffect, useState } from "react";
import Image from "../components/common/Image";
import { Container, Nav, Navbar } from "react-bootstrap";
import { HeaderProps } from "../utils/Interfaces";
import * as Head from "../components/common/Header"

const navStyleMobile: React.CSSProperties = {
    marginLeft: "1em",
    marginRight: "1em",
    color: "black",
    backgroundColor: "white",
    width: "100%",
    borderRadius: "5px",
    marginTop: "1px",
    paddingLeft: "10px"
}

const navStyleWide = (textAlignValue: "right" | "center"): React.CSSProperties => {
    return {
        marginLeft: "1em",
        marginRight: "1em",
        color: "white",
        width: "50%",
        textAlign: textAlignValue
    }
}

export default (props: HeaderProps) => {    

    return (
        <Navbar expand="lg" id="title-bar" className="bg-body-tertiary" style={{
            height: "auto",
            backgroundColor: "#2e025a !important"
        }}>
            <Head.default title={props.meta} meta={props.meta} />
            <Container style={{
                marginLeft: "0%",
                marginRight: "0%",
                maxWidth: "unset"
            }}>
                <Navbar.Brand href="/" style={{
                    display: "flex"
                }}>
                    <Image
                        alt=""
                        src={require("../images/logo.png")}
                        className="d-inline-block align-top"
                        style={{
                            width: "20%",
                            height: "20%"
                        }}
                        />
                    <h3 style={{
                        color: "white",
                        marginTop: "1em",
                        display: props.lowerWidthDisplay
                    }}>Simple Validator</h3>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" style={{
                    backgroundColor: "white"
                }} />
                <Navbar.Collapse>
                    <Nav className="me-auto" style={{
                        margin: "auto",
                        fontSize: "20px",
                        width: "40%"
                    }}>
                        <Nav.Link style={ navStyleWide(props.textAlignValue) } href="/">Home</Nav.Link>
                        <Nav.Link style={ navStyleWide(props.textAlignValue) } href="/ContactUs">Contact Us</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}